import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import './ContactForm.scss'
import {IoMailOpenOutline} from 'react-icons/io5'

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_ajw1zls', 'template_q512twj', form.current, 'E9fOjxEWuF6i0DiZg')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
    }
    return (
        <div className="contact">
            <div className='container'>
                <div className='title'>Imate neka pitanja?</div>
                <div className='contact-form'>
                    <div className='form-icon'>
                        <i><IoMailOpenOutline/></i>
                    </div>
                    <div className='form-container'>
                        <form ref={form} onSubmit={sendEmail}>
                            <h1>Popunite obrazac!</h1>
                            <p>Veoma je jednostavno!</p>
                            <div className='input-box'>
                                <input type="text" name="name" required="required" />
                                <label>Ime i prezime</label>
                            </div>
                            <div className='input-box'>
                                <input type="text" name="email" required="required" />
                                <label>Email</label>
                            </div>
                            <div className='input-box'>
                                <textarea name="message" required="required" rows='5' />
                                <label>Vaša poruka</label>
                            </div>
                            <div className='input-box'>
                                <input type="submit" value="Pošaljite" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact