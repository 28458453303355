import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Layout from './Layout'
import Home from './pages/Home'
import Gallery from "./pages/Gallery"
import AlarmniSistemi from './pages/usluge/AlarmniSistemi'
import AudioAkustika from './pages/usluge/AudioAkustika'
import Interfoni from './pages/usluge/Interfoni'
import KontrolaPristupa from './pages/usluge/KontrolaPristupa'
import RacunarskeMreze from './pages/usluge/RacunarskeMreze'
import SoftverskeUsluge from './pages/usluge/SoftverskeUsluge'
import TelefonskeCentrale from './pages/usluge/TelefonskeCentrale'
import VatrodojavniSistemi from './pages/usluge/VatrodojavniSistemi'
import VideoNadzor from "./pages/usluge/VideoNadzor"

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>} />
            <Route path="/alarmni_sistemi" element={<AlarmniSistemi/>} />
            <Route path="/audio_akustika" element={<AudioAkustika/>} />
            <Route path="/interfoni" element={<Interfoni/>} />
            <Route path="/kontrola_pristupa" element={<KontrolaPristupa/>} />
            <Route path="/racunarske_mreze" element={<RacunarskeMreze/>} />
            <Route path="/softverske_usluge" element={<SoftverskeUsluge/>} />
            <Route path="/telefonske_centrale" element={<TelefonskeCentrale/>} />
            <Route path="/vatrodojavni_sistemi" element={<VatrodojavniSistemi/>} />
            <Route path="/video_nadzor" element={<VideoNadzor/>} />
            <Route path="/galerija" element={<Gallery/>} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;