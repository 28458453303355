import React from 'react'
import './ServiceCards.scss'
import Image from '../images/SecureCam-FrontLogo.png'
import {GiCctvCamera} from 'react-icons/gi'
import {ImFire} from 'react-icons/im'
import {RiAlarmWarningLine, RiComputerLine} from 'react-icons/ri'
import {MdOutlinePhoneInTalk, MdOutlinePhoneAndroid} from 'react-icons/md'
import {VscSourceControl} from 'react-icons/vsc'
import {GrCloudSoftware} from 'react-icons/gr'
import {SiAirplayaudio} from 'react-icons/si'

const ServiceCards = () => {
  return (
    <div className='service'>
        <div className='container'>
            <div className='title'>NAŠE USLUGE</div>
            <div className='paragraph'>Soft-cam se bavi uslugama projektovanja instalacije i ugradnje sigurnosnih sistema:</div>
            <div className='cards-container'>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><RiAlarmWarningLine/></i>
                            <h3>Alarmni sistemi</h3>
                            <hr/>
                            <p>Usluga prodaje montaže, ugradnje žičnih i bežičnih alarmnih sistema. (DSC, ParadoX, itd.)</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/alarmni_sistemi">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><SiAirplayaudio/></i>
                            <h3>Audio akustika</h3>
                            <hr/>
                            <p>Prodaja i ugradnja audio akustike za fizička i pravna lica. (kancelarijska ozvučenja, ugostiteljska-ambijentalna ozvučenja)</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/audio_akustika">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><MdOutlinePhoneInTalk/></i>
                            <h3>Interfoni</h3>
                            <hr/>
                            <p>Prodaja i ugradnja interfonskih sistema za zgrade, kuće, kao i ostale uslovno-stambene objekte. (Hikvision, Home, Urmet, itd.)</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/interfoni">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><VscSourceControl/></i>
                            <h3>Kontrola pristupa</h3>
                            <hr/>
                            <p>Prodaja i ugradnja uređaja za kontrolu pristupa, kao i uređaja za evidenciju radnog vremena. (ulaz pristup pomoću šifre, tag/čip, otiska prsta, skeniranja oka)</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/kontrola_pristupa">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><RiComputerLine/></i>
                            <h3>Računarske mreže</h3>
                            <hr/>
                            <p>Usluga izrade računarsko-telefonske mreže u svim kategorijama.</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/racunarske_mreze">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><GrCloudSoftware/></i>
                            <h3>Softverske usluge</h3>
                            <hr/>
                            <p>Prodaja softverskih usluga poput izrade websajtova.</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/softverske_usluge">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><MdOutlinePhoneAndroid/></i>
                            <h3>Telefonske centrale</h3>
                            <hr/>
                            <p>Ugradnja i programiranje VoIP telefonskih centrala. (Panasonic)</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/telefonske_centrale">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><ImFire/></i>
                            <h3>Vatrodojavni sistemi</h3>
                            <hr/>
                            <p>Prodaja i montaža vatrodojavnih sistema svih vrsta.</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/vatrodojavni_sistemi">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <div className='card-front'>
                            <i><GiCctvCamera/></i>
                            <h3>Video nadzor</h3>
                            <hr/>
                            <p>Prodaja i montaža visoko kvalitetne opreme za video nadzor. (Hikvision, DaHua, TVT, itd.)</p>
                        </div>
                        <div className='card-back'>
                            <img src={Image} alt='' />
                            <a href="/video_nadzor">
                                <button>Pročitajte još</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceCards