import React from 'react'
import './WIP.scss'
import {ImSpinner2} from 'react-icons/im'

const WIP = () => {
  return (
    <div className='WIP'>
        <div className='container'>
            <div className='title'>Trenutno u radu!</div>
            <div className='paragraph'>Ova sekcija sajta je trenutno u radu. Dođite ponovo kasnije!</div>
            <div className='spinner'>
                <i><ImSpinner2/></i>
            </div>
        </div> 
    </div>
  )
}

export default WIP