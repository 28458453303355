import React, {useState, useEffect} from 'react'
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs'
import Image1 from '../images/Image1.jpg'
import Image2 from '../images/Image2.jpg'
import Image3 from '../images/Image3.jpg'
import Image4 from '../images/Image4.jpg'
import Image5 from '../images/Image5.jpg'
import Image6 from '../images/Image6.jpg'
import './Carousel.scss'

const imageHandler = [Image1, Image2, Image3, Image4, Image5, Image6]

const ArrayHandler = ({array, slide, index}) => {
  return (
    array.map((img, key) => (
      <img key={key} src={img} onClick={() => slide(key)} className={index === key ? 'slide active' : 'slide'} alt=""/>
    ))
  )
}

const CarouselHandler = ({imgArray}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex(slideIndex === imageHandler.length - 1 ? 0 : slideIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [slideIndex]);

  const prev = () => {slideIndex === 0  ? setSlideIndex(imgArray.length - 1) : setSlideIndex(slideIndex - 1)}
  const next = () => {slideIndex === imgArray.length - 1 ? setSlideIndex(0) : setSlideIndex(slideIndex + 1)}
  const moveDot = (slideIndex) => setSlideIndex(slideIndex);

  return (
    <div className='slideshow'>
      <img className='main-img' src={imgArray[slideIndex]} alt=""/>
      <div className='actions'>
        <button className='prev' onClick={prev}><BsArrowLeft/></button>
        <button className='next' onClick={next}><BsArrowRight/></button>
      </div>
      <div className='dots'>
        {Array.from({length: imageHandler.length}).map((item, index) => (
          <div key={index} onClick={() => moveDot(index)} className={slideIndex === index ? 'dot active' : 'dot'}></div>
        ))}
      </div>
      <div className='image-array'>
        <ArrayHandler array={imgArray} slide={setSlideIndex} index={slideIndex}/>
      </div>
    </div>
  )
}

const Carousel = () => {
  return (
    <div className='carousel'>
      <CarouselHandler imgArray={imageHandler}/>
    </div>
  )
}

export default Carousel