import React from 'react'
import {AiOutlineMail} from "react-icons/ai"
import {GoLocation} from "react-icons/go"
import {Link} from 'react-router-dom'
import Image from '../images/SecureCam-FrontLogo.png'
import './Footer.scss'

const Footer = () => {
    const year = new Date().getFullYear()
  return (
    <footer>
      <div className='container'>
        <div className='footer-top'>
          <div className='footer-top-item'>
            <img src={Image} alt="Logo"></img>
            <p>Ukoliko ste zainteresovani za bilo koju od naših usluga, slobodno nas kontaktirajte putem ponuđenih podataka.</p>
          </div>
          <div className='footer-top-item'>
            <h2>Kategorije</h2>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/galerija">Galerija</Link></li>
            </ul>
          </div>
          <div className='footer-top-item'>
            <h2>Kontakt info</h2>
            <ul>
              <li><AiOutlineMail /> soft.cam2022@gmail.com</li>
              <li><GoLocation /> Banja Luka, 78000</li>
            </ul>
          </div>
        </div>
        <div className='footer-bottom'>
          <p>© <span>{year}</span> Soft-Cam</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer