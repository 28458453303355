import React from 'react'
import Carousel from '../../components/Carousel'
import Banner from '../../components/Banner'

const VideoNadzor = () => {
  return (
    <div className='features'>
      <Carousel/>
      <Banner/>
    </div>
  )
}

export default VideoNadzor