import React from 'react'
import {NavLink} from 'react-router-dom'
import './Menu.scss'

const MainMenu = (props) => {
  return (
    <>
      <ul className={`menu${props.active ? ' active' : ''}`}>
        <li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to="/galerija">Galerija</NavLink></li>
      </ul>
    </>
  )
}

export default MainMenu