import React from 'react'
import Carousel from '../components/Carousel'
import WIP from '../components/WIP'

const Gallery = () => {
  return (
    <div className='features'>
      <Carousel/>
      <WIP/>
    </div>
  )
}

export default Gallery