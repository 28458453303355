import React from 'react'
import './Intro.scss'
import Image from '../images/Background2.jpg'

const Intro = () => {
  return (
    <div className='intro'>
      <div className='container'>
        <div className='info'>
          <h1>Da se upoznamo...</h1>
          <p>Iza nas je niz uspješno završenih projekata kako za fizička, tako i za pravna lica. Naš osnovni koncept je kvalitetno projektovanje, kao i udovoljavanje zahtjevima klijenata. Pored usluga nudimo i široku lepezu proizvoda vodećih svjetskih brendova video nadzora, vatrodojave, kontrole pristupa, audio i video interfonskih sistema, audio akustike, kao i softverske usluge - izrada websajtova.</p>
        </div>
        <div className='image'>
          <img src={Image} alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Intro