import React from 'react'
import Carousel from '../components/Carousel'
import Intro from '../components/Intro'
import ServiceCards from '../components/ServiceCards'
import ContactForm from '../components/ContactForm'

const Home = () => {
  return (
    <div className='features'>
      <Carousel/>
      <Intro/>
      <ServiceCards/>
      <ContactForm/>
    </div>
  )
}

export default Home