import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import Logo from '../images/SecureCam-FrontLogo.png'
import './Navbar.scss'

const Navbar = () => {
    const [active, setActive] = useState(false)
    function menuHandler() {
        setActive(!active)
    }
  return (
    <nav>
        <div className='container'>
            <div className='logo'>
                <Link to="/"><img src={Logo} alt="Logo" /></Link>
            </div>
            <div className='mobile-btn'>
                <button onClick={menuHandler} className={`menu-btn${active ? ' active' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <Menu active={active} />
        </div>
    </nav>
  )
}

export default Navbar